import React, { useEffect, useRef } from 'react';
import HeroSection from '../../../components/organism/heroSection/index.tsx';
import Navbar from '../../../components/organism/navbar/index.tsx';
import InfoContent from '../../../components/organism/infoContent/index.tsx';
import './index.css'
import InfoSecondary from '../../../components/molecule/infoSecondary/index.tsx';
import SendMessage from '../../../components/organism/sendMessage/index.tsx';
import Footer from '../../../components/organism/footer/index.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store.ts';
import { SlideIn } from '../../../hooks/slideIn.ts';
import { ScrollTo } from '../../../hooks/scrollTo.ts';

const Home : React.FC = () => { 
  const { windowWidth, loaderShown} = useSelector((state: RootState) => state)

  const contactRef = useRef<HTMLDivElement | null>(null)
  const elementsInView = useRef<HTMLDivElement[]>([]);

  ScrollTo({top: 0, behaviour: 'instant'})
  SlideIn({elementsInView: elementsInView})

   const scrollToContact = () => {
    if(contactRef.current !== null) {
      contactRef.current.scrollIntoView(false)
    }
  }    

   // run this function from an event handler or an effect to execute scroll 
   useEffect(() => {
    if(loaderShown) {
      document.querySelector('.body-container')?.classList.add('position-initial')
    }

    }, [])

  return (
    <div className='body-container'>
      <Navbar scrollToContact={scrollToContact} />
      <HeroSection />
      <InfoContent
        ref={(el: HTMLDivElement) => elementsInView.current[0] = el}
        className='offset' 
        imageLeft={false} 
        imageUrl={require('../../../assets/mobile-image.png')} 
        title ={ ["Stvaramo mobilne aplikacije po tvojoj mjeri"]}
        subtitle='Zamisli aplikaciju koja prati tvoje svakodnevne korake, organizira tvoje ideje ili pomaže tvojoj maloj firmi da raste. To su upravo vrste aplikacija koje stvaramo - intuitivne, korisne i prilagođene tvojim potrebama.'
      > 
        <div className='info-text-info-secondary-container row'>
          <InfoSecondary title='Individualni pristup' text="Svaki klijent je jedinstven, stoga prilagođavamo naš pristup prema individualnim potrebama i ciljevima." />
          
          {windowWidth >= 768 &&  <div className='spacer100' />}
          
          <InfoSecondary title='Inovativnost' text='Osiguravamo da vaša aplikacija ne samo prati, već i postavlja standarde u industriji.' />
        </div>
      </InfoContent>
      
      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[1] = el}
        className='offset' 
        imageLeft={true} 
        imageUrl={require('../../../assets/graphic-design.png')} 
        title={'Nudimo usluge grafičkog dizajna'}
        subtitle='Posvećeni smo stvaranju vizualnih priča koje privlače pažnju i ističu se u gomili. Razumijemo važnost prvog dojma i želimo vam pomoći da ostavite snažan i pozitivan dojam na svoje ciljano tržište. Jer vaš uspjeh je naša inspiracija.'
        > 
        <div className='info-text-info-secondary-container row'>
          <ul className='info-content-list-item'>
            <li>
              Stvaramo vizualni identitet
            </li>
            <li>
              Pomažemo da se istaknete u moru konkurencije
            </li>
            <li>
              Analiziramo trendove i stvaramo dizajn koji odgovara vašim željama i ciljevima.
            </li>
          </ul>
        </div>  
      </InfoContent>
      
      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[2] = el}
        className='offset' 
        isLast 
        imageLeft={false} 
        imageUrl={require('../../../assets/website.png')} 
        title={'Izrađujemo web stranice koje oblikuju tvoju digitlnu priču'}  
        subtitle='Tvoja web stranica nije samo digitalna prisutnost - ona je tvoja priča, tvoj glas na internetu. Kroz našu uslugu izrade web stranica, oblikujemo tvoju priču na mreži kako bi odrazila tvoje jedinstvene vrijednosti i ciljeve.'
      >
        {/* <div className='info-text-info-secondary-container row'>
          <InfoSecondary title='Predlošci web stranica' text="Istražite našu kolekciju unaprijed dizajniranih predložaka web stranica koji su spremni za personalizaciju prema vašim jedinstvenim potrebama i stilu." />
        </div> */}
      </InfoContent>

      {/* <Reviews /> */}

      <SendMessage 
        className='offset' 
        ref={(el: HTMLDivElement) => elementsInView.current[3] = el}
        lang='hr' 
      />

      <Footer lang='hr' 
        className='offset' 
        ref={(el: HTMLDivElement) => {
          elementsInView.current[4] = el;
          
          // @ts-ignore
          contactRef.current = el
        }}
      />
    </div>
  )
}

export default Home