// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_rh_S3csNwxoJzD3BWxk8PVTAgHmLS0o",
  authDomain: "wedding-94824.firebaseapp.com",
  projectId: "wedding-94824",
  storageBucket: "wedding-94824.appspot.com",
  messagingSenderId: "119553693415",
  appId: "1:119553693415:web:d6d285fbfd64ece263f442",
  measurementId: "G-X6BNFGQ2SR"
};

// Initialize Firebase
const weddingCameraApp = initializeApp(firebaseConfig);
export const weddingStorage = getStorage(weddingCameraApp)
export const weddingDb = getFirestore(weddingCameraApp);
