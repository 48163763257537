import React from 'react';
import './index.css'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store.ts';

interface Props {
  isHome?: boolean;
  title?: string;
  subtitle?: string | []
}

const HeroSection : React.FC<Props> = ({isHome = true, 
  title = "Svaki kôd ima priču, svaki dizajn ima svrhu.",
  subtitle= "Oživljavamo vaše ideje u digitalnom svijetu. Kreiramo web stranice, mobilne aplikacije i digitalne proizvode."
}) => {
  const { windowWidth } = useSelector((state: RootState) => state)


  if(!isHome) {
    if(windowWidth <= 500) {
      return <div className='hero-container hero-container-mobile'>
        <div className='hero-image'> 
          <div className='hero-title-content-left container'>
            <div className='hero-text-left'>
              <h1 className='hero-main-title-left'>
                {title}
              </h1>
    
              <h2 className='hero-sub-title-left'>
                {subtitle}
              </h2>
            </div>
          
          </div>
        </div>
      </div>
    } else {
      return (
        <div className='hero-container'>
          <div className='hero-image'> 
            <div className='hero-title-content-left container'>
              <div className='hero-text-left'>
                <h1 className='hero-main-title-left'>
                  {title}
                </h1>
      
                <h2 className='hero-sub-title-left'>
                  {subtitle}
                </h2>
              </div>
            
            </div>
          </div>
        </div>
      )
    }
  }


  if(windowWidth <= 500) {
    console.log('da')
    return <div className='hero-container hero-container-mobile'>
      <div className='hero-image'> 
        <div className='hero-title-content-left container'>
          <div className='hero-text-left'>
            <h1 className='hero-main-title-left'>
              {title}
            </h1>
  
            <h2 className='hero-sub-title-left'>
              {subtitle}
            </h2>
          </div>
        
        </div>
      </div>
    </div>
  }

  return (
    <div className='hero-container'>
      <div className='hero-image'/>

      <div className='hero-title-container container'>
        <div className='hero-text'>
          <h1 className='hero-main-title'>
            {title}
          </h1>

          <h2 className='hero-sub-title'>
            {subtitle}
          </h2>
        </div>
      
      </div>
    </div>
  )
}

export default HeroSection